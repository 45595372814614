@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.section {
    padding: 35px 0px;
    box-sizing: border-box;
}

.main > h2 {
    font-family: "Montserrat", serif;
    /* font-optical-sizing: auto; */
    font-weight: 350;
    /* font-style: normal; */
    
}
.main {
    text-align: center;
}

.main > h3 {
    margin-top: 0;
    font-family: "Lato", serif;
    font-weight: 400;
    font-style: italic;
}

.main > h3:last-child {
    margin-top: 0;
    margin-bottom: 30px;
}

.itemContainer {
    display: flex;
}
.textContainer {
    width: 280px;
}

.itemContainer > div > p {
    font-family: "Open Sans", serif;
    font-weight: 500;
    font-style: normal;
}

.signature {
    margin-top: 20px;
}
.signature > p:first-child {
    font-family: "Lato", serif;
    font-weight: 600; 
    font-weight: bold;
}
.signature > p {
    margin: 0;
    font-family: "Lato", serif;
    font-weight: 400;
}

.signature > img {
    margin-top: 20px;
    border-radius: 50%;
}




.list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: centers;
    flex-wrap: wrap;
}
.list > li {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 35px;
    margin-bottom: 35px;
}