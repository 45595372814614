.service {
    padding: 35px 0px;
    box-sizing: border-box;
    background-color: #ececec;
}

.service .main > h2 {
    font-family: "Montserrat", serif;
    font-weight: 350;
}

.service .main {
    text-align: center;
}

.service .list {
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-wrap: wrap;
    margin: 0 auto;
}

.service .item {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.service .itemHeader {
    display: flex;
    margin-bottom: 35px;
}

.service .itemHeader > h3 {
    margin: 0;
}

.service .itemHeader>img {
    margin-right: 20px;
}

.service .textItem {
    width: 275px;
}
.service .itemHeader {
    max-width: 275px;
    align-items: center;
}

.additionalList {
    margin-left: 10px;
    list-style: circle;
}